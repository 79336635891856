import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

const DesignIndex = loadable(() => import("../content/design-index"));
const DesignColor = loadable(() => import("../content/design-color"));
const DesignTypography = loadable(() => import("../content/design-typography"));
const DesignButton = loadable(() => import("../content/design-button"));
const DesignSpacing = loadable(() => import("../content/design-spacing"));
const DesignIcons = loadable(() => import("../content/design-icons"));
const DesignMediaBlock = loadable(() =>
  import("../content/design-media-block"),
);
const DesignHero = loadable(() => import("../content/design-hero"));
const DesignHeroRight = loadable(() => import("../content/design-hero-right"));
const DesignHeroTitle = loadable(() => import("../content/design-hero-title"));
const DesignHeroSmall = loadable(() => import("../content/design-hero-small"));
const DesignPLPHero = loadable(() => import("../content/design-plp-hero"));
const DesignHeroCondensed = loadable(() =>
  import("../content/design-hero-condensed"),
);
const DesignProductBlock = loadable(() =>
  import("../content/design-product-block"),
);
const DesignProductBlockFull = loadable(() =>
  import("../content/design-product-block-full"),
);
const DesignQuoteHero = loadable(() => import("../content/design-quote-hero"));
const DesignReviewHero = loadable(() =>
  import("../content/design-review-hero"),
);
const DesignEmailHero = loadable(() => import("../content/design-email-hero"));
const DesignAnnouncement = loadable(() =>
  import("../content/design-announcement"),
);
const DesignStoryBlock = loadable(() =>
  import("../content/design-story-block"),
);
const DesignGuideItem = loadable(() => import("../content/design-guide-item"));
const DesignNavigation = loadable(() => import("../content/design-navigation"));
const DesignNavHeader = loadable(() => import("../content/design-nav-header"));
const DesignNavFooter = loadable(() => import("../content/design-nav-footer"));
const DesignMediaGroup = loadable(() =>
  import("../content/design-media-group"),
);
const DesignKSP = loadable(() => import("../content/design-ksp"));
const DesignTrial = loadable(() => import("../content/design-trial"));
const DesignVideo = loadable(() => import("../content/design-video"));
const DesignImage = loadable(() => import("../content/design-image"));
const DesignEditorial = loadable(() => import("../content/design-editorial"));
const DesignLargeEditorial = loadable(() =>
  import("../content/design-large-editorial"),
);
const DesignText = loadable(() => import("../content/design-text"));
const DesignSticker = loadable(() => import("../content/design-sticker"));
const DesignTickerText = loadable(() =>
  import("../content/design-ticker-text"),
);
const DesignTickerIcon = loadable(() =>
  import("../content/design-ticker-icon"),
);
const DesignDrawer = loadable(() => import("../content/design-drawer"));
const DesignRelatedGroup = loadable(() =>
  import("../content/design-related-group"),
);
const DesignGuideGroup = loadable(() =>
  import("../content/design-guide-group"),
);
const DesignQuoteCarousel = loadable(() =>
  import("../content/design-quote-carousel"),
);
const DesignLifestyle = loadable(() => import("../content/design-lifestyle"));
const DesignProductHero = loadable(() =>
  import("../content/design-product-hero"),
);
const DesignProductPanel = loadable(() =>
  import("../content/design-product-panel"),
);
const DesignBundlePanel = loadable(() =>
  import("../content/design-bundle-panel"),
);
const DesignCollection = loadable(() => import("../content/design-collection"));
const DesignCollectionBG = loadable(() =>
  import("../content/design-collection-bg"),
);
const DesignCardCarousel = loadable(() =>
  import("../content/design-card-carousel"),
);
const DesignCardGroup = loadable(() => import("../content/design-card-group"));
const DesignReviews = loadable(() => import("../content/design-reviews"));
const DesignPressSmall = loadable(() =>
  import("../content/design-press-small"),
);
const DesignGrid = loadable(() => import("../content/design-grid"));
const DesignMiscUI = loadable(() => import("../content/design-misc-ui"));
const DesignBadge = loadable(() => import("../content/design-badge"));
const DesignInput = loadable(() => import("../content/design-input"));
const DesignTextarea = loadable(() => import("../content/design-textarea"));
const DesignDropdown = loadable(() => import("../content/design-dropdown"));
const DesignCheckbox = loadable(() => import("../content/design-checkbox"));
const DesignForm = loadable(() => import("../content/design-form"));
const DesignOptionGroup = loadable(() =>
  import("../content/design-option-group"),
);
const DesignSwatchGroup = loadable(() =>
  import("../content/design-swatch-group"),
);
const DesignBreadcrumbs = loadable(() =>
  import("../content/design-breadcrumbs"),
);
const DesignModal = loadable(() => import("../content/design-modal"));
const DesignBasicGrid = loadable(() => import("../content/design-basic-grid"));
const DesignBasicGridBG = loadable(() =>
  import("../content/design-basic-grid-bg"),
);
const DesignBasicGridImage = loadable(() =>
  import("../content/design-basic-grid-image"),
);
const DesignFullGrid = loadable(() => import("../content/design-full-grid"));
const DesignFullGridGutter = loadable(() =>
  import("../content/design-full-grid-gutter"),
);
const DesignBreakpoints = loadable(() =>
  import("../content/design-breakpoints"),
);

const DesignTemplate = ({ pageContext }) => {
  const designProps = pageContext.props;

  const clone = (obj) => ({ ...obj });
  const renameKey = (object, key, newKey) => {
    const clonedObj = clone(object);
    const targetKey = clonedObj[key];

    if (targetKey) {
      delete clonedObj[key];
      clonedObj[newKey] = targetKey;
    }
    return clonedObj;
  };

  const getDesignData = (slug) => {
    // Rename classNames prop to className
    const props = renameKey(
      { ...designProps.find((p) => p.slug === slug).data },
      "classNames",
      "className",
    );
    return { ...props, slug };
  };

  let component = <DesignIndex />;
  if (pageContext.id === "color") {
    component = <DesignColor />;
  }
  if (pageContext.id === "typography") {
    component = <DesignTypography />;
  }
  if (pageContext.id === "button") {
    component = <DesignButton />;
  }
  if (pageContext.id === "spacing") {
    component = <DesignSpacing />;
  }
  if (pageContext.id === "icons") {
    component = <DesignIcons />;
  }
  if (pageContext.id === "media-block") {
    component = <DesignMediaBlock />;
  }
  if (pageContext.id === "hero") {
    component = <DesignHero props={getDesignData("hero")} />;
  }
  if (pageContext.id === "hero-right") {
    component = <DesignHeroRight props={getDesignData("hero-right")} />;
  }
  if (pageContext.id === "hero-title") {
    component = <DesignHeroTitle props={getDesignData("hero-title")} />;
  }
  if (pageContext.id === "hero-small") {
    component = <DesignHeroSmall props={getDesignData("hero-small")} />;
  }
  if (pageContext.id === "plp-hero") {
    component = <DesignPLPHero props={getDesignData("plp-hero")} />;
  }
  if (pageContext.id === "hero-condensed") {
    component = <DesignHeroCondensed props={getDesignData("hero-condensed")} />;
  }
  if (pageContext.id === "product-block") {
    component = <DesignProductBlock props={getDesignData("product-block")} />;
  }
  if (pageContext.id === "product-block-full") {
    component = (
      <DesignProductBlockFull props={getDesignData("product-block-full")} />
    );
  }
  if (pageContext.id === "quote-hero") {
    component = <DesignQuoteHero props={getDesignData("quote-hero")} />;
  }
  if (pageContext.id === "review-hero") {
    component = <DesignReviewHero props={getDesignData("review-hero")} />;
  }
  if (pageContext.id === "email-hero") {
    component = <DesignEmailHero props={getDesignData("email-hero")} />;
  }
  if (pageContext.id === "story-block") {
    component = <DesignStoryBlock props={getDesignData("story-block")} />;
  }
  if (pageContext.id === "guide-item") {
    component = <DesignGuideItem props={getDesignData("guide-item")} />;
  }
  if (pageContext.id === "video") {
    component = <DesignVideo props={getDesignData("video")} />;
  }
  if (pageContext.id === "image") {
    component = <DesignImage props={getDesignData("image")} />;
  }
  if (pageContext.id === "announcement") {
    component = <DesignAnnouncement props={getDesignData("announcement")} />;
  }
  if (pageContext.id === "editorial") {
    component = <DesignEditorial props={getDesignData("editorial")} />;
  }
  if (pageContext.id === "large-editorial") {
    component = (
      <DesignLargeEditorial props={getDesignData("large-editorial")} />
    );
  }
  if (pageContext.id === "text") {
    component = <DesignText props={getDesignData("text")} />;
  }
  if (pageContext.id === "sticker") {
    component = <DesignSticker props={getDesignData("sticker")} />;
  }
  if (pageContext.id === "ticker-text") {
    component = <DesignTickerText props={getDesignData("ticker-text")} />;
  }
  if (pageContext.id === "ticker-icon") {
    component = <DesignTickerIcon props={getDesignData("ticker-icon")} />;
  }
  if (pageContext.id === "lifestyle") {
    component = <DesignLifestyle props={getDesignData("lifestyle")} />;
  }
  if (pageContext.id === "info-block") {
    component = <DesignDrawer props={getDesignData("info-block")} />;
  }
  if (pageContext.id === "related-group") {
    component = <DesignRelatedGroup props={getDesignData("related-group")} />;
  }
  if (pageContext.id === "guide-group") {
    component = <DesignGuideGroup props={getDesignData("guide-group")} />;
  }
  if (pageContext.id === "quote-carousel") {
    component = <DesignQuoteCarousel props={getDesignData("quote-carousel")} />;
  }
  if (pageContext.id === "navigation") {
    component = <DesignNavigation />;
  }
  if (pageContext.id === "header") {
    component = <DesignNavHeader props={getDesignData("header")} />;
  }
  if (pageContext.id === "footer") {
    component = <DesignNavFooter props={getDesignData("footer")} />;
  }
  if (pageContext.id === "media-group") {
    component = <DesignMediaGroup />;
  }
  if (pageContext.id === "ksp") {
    component = <DesignKSP props={getDesignData("ksp")} />;
  }
  if (pageContext.id === "trial") {
    component = <DesignTrial props={getDesignData("trial")} />;
  }
  if (pageContext.id === "product-hero") {
    component = <DesignProductHero props={getDesignData("product-hero")} />;
  }
  if (pageContext.id === "product-panel") {
    component = <DesignProductPanel props={getDesignData("product-panel")} />;
  }
  if (pageContext.id === "bundle-panel") {
    component = <DesignBundlePanel props={getDesignData("bundle-panel")} />;
  }
  if (pageContext.id === "collection") {
    component = <DesignCollection props={getDesignData("collection")} />;
  }
  if (pageContext.id === "collection-bg") {
    component = <DesignCollectionBG props={getDesignData("collection-bg")} />;
  }
  if (pageContext.id === "card-carousel") {
    component = <DesignCardCarousel props={getDesignData("card-carousel")} />;
  }
  if (pageContext.id === "card-group") {
    component = <DesignCardGroup props={getDesignData("card-group")} />;
  }
  if (pageContext.id === "reviews") {
    component = <DesignReviews props={getDesignData("reviews")} />;
  }
  if (pageContext.id === "press-small") {
    component = <DesignPressSmall props={getDesignData("press-small")} />;
  }
  if (pageContext.id === "misc-ui") {
    component = <DesignMiscUI />;
  }
  if (pageContext.id === "badge") {
    component = <DesignBadge />;
  }
  if (pageContext.id === "input") {
    component = <DesignInput />;
  }
  if (pageContext.id === "textarea") {
    component = <DesignTextarea />;
  }
  if (pageContext.id === "dropdown") {
    component = <DesignDropdown />;
  }
  if (pageContext.id === "checkbox") {
    component = <DesignCheckbox />;
  }
  if (pageContext.id === "form") {
    component = <DesignForm />;
  }
  if (pageContext.id === "option-group") {
    component = <DesignOptionGroup />;
  }
  if (pageContext.id === "swatch-group") {
    component = <DesignSwatchGroup />;
  }
  if (pageContext.id === "breadcrumbs") {
    component = <DesignBreadcrumbs />;
  }
  if (pageContext.id === "modal") {
    component = <DesignModal />;
  }
  if (pageContext.id === "grid") {
    component = <DesignGrid />;
  }
  if (pageContext.id === "basic-grid") {
    component = <DesignBasicGrid props={getDesignData("basic-grid")} />;
  }
  if (pageContext.id === "basic-grid-bg") {
    component = <DesignBasicGridBG props={getDesignData("basic-grid-bg")} />;
  }
  if (pageContext.id === "basic-grid-image") {
    component = (
      <DesignBasicGridImage props={getDesignData("basic-grid-image")} />
    );
  }
  if (pageContext.id === "full-grid") {
    component = <DesignFullGrid props={getDesignData("full-grid")} />;
  }
  if (pageContext.id === "full-grid-gutter") {
    component = (
      <DesignFullGridGutter props={getDesignData("full-grid-gutter")} />
    );
  }
  if (pageContext.id === "breakpoints") {
    component = <DesignBreakpoints />;
  }

  return component;
};

DesignTemplate.propTypes = {
  pageContext: PropTypes.shape({
    design: PropTypes.string,
    id: PropTypes.string,
    props: PropTypes.arrayOf(PropTypes.any),
  }),
};

DesignTemplate.defaultProps = {
  pageContext: { layout: "design", id: "index", props: [] },
};

export default DesignTemplate;
